.schedule-tab {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__container-info {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 200px 200px;
  }
}