@import "~@edx/brand/paragon/fonts";
@import "~@edx/brand/paragon/variables";
@import "~@edx/paragon/scss/core/core";
@import "~@edx/brand/paragon/overrides";

@import "~@skillfactory/frontend-component-footer/dist/footer";

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100vh;

  main {
    flex-grow: 1;
  }

  header {
    flex: 0 0 auto;

    .logo {
      display: block;
      box-sizing: content-box;
      position: relative;
      top: 0.1em;
      height: 1.75rem;
      margin-right: 1rem;

      img {
        display: block;
        height: 100%;
      }
    }
  }

  footer {
    flex: 0;
  }
}

.course-tabs-navigation {
  border-bottom: solid 1px #eaeaea;

  .nav a,
  .nav button {
    &:hover {
      background-color: $light-400;
    }
  }

  .nav a {
    &:not(.active):hover {
      background-color: $light-400;
      border-bottom: none;
    }
  }
}

.nav-underline-tabs {
  margin: 0 0 -1px;

  .nav-link {
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
    color: $gray-700;

    // temporary until we can remove .btn class from dropdowns
    border-left: 0;
    border-right: 0;
    border-radius: 0;

    &:hover,
    &:focus,
    &.active {
      font-weight: var(--font-weight-normal);
      color: $primary-500;
      border-bottom-color: $primary-500;
    }
  }
}

.sequence-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 4rem;

  // On mobile, the unit container will be responsible
  // for container padding.
  @media (min-width: map-get($grid-breakpoints, "sm")) {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

.sequence {
  @media (min-width: map-get($grid-breakpoints, "sm")) {
    border: solid 1px #eaeaea;
    border-radius: 4px;
  }
}

.sequence-navigation {
  display: flex;

  @media (min-width: map-get($grid-breakpoints, "sm")) {
    margin: -1px -1px 0;
  }

  .btn {
    flex-grow: 1;
    display: inline-flex;
    border-radius: 0;
    border: solid 1px #eaeaea;
    border-left-width: 0;
    position: relative;
    font-weight: 400;
    padding: 0 0.375rem;
    height: 3rem;
    justify-content: center;
    align-items: center;
    color: $gray-500;
    white-space: nowrap;

    &:hover,
    &:focus,
    &.active {
      color: $gray-700;
    }

    &:focus {
      z-index: 1;
    }

    &.active {
      &:after {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        height: 2px;
        background: $primary;
      }
    }

    &.complete {
      background-color: #eef7e5;
      color: $success;
    }

    &:first-child {
      border-left-width: 0;
    }

    &:last-child {
      border-right-width: 0;
    }
  }

  .sequence-navigation-tabs-container {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;
    display: flex;
    // min-width 0 prevents the flex item from overflowing the parent container
    // https://dev.to/martyhimmel/quick-tip-to-stop-flexbox-from-overflowing-peb
    min-width: 0;
  }

  .sequence-navigation-tabs {
    .btn {
      flex-basis: 100%;
      min-width: 2rem;
    }
  }

  .sequence-navigation-dropdown {
    .dropdown-menu .btn {
      flex-basis: 100%;
      min-width: 4rem;
      padding-left: 1rem;
      padding-right: 1rem;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      border-right: 0;

      &+.btn {
        border-top: 0;
      }

      .unit-title {
        flex-grow: 1;
        text-align: left;
        overflow: hidden;
        min-width: 0;
        margin: 0 1rem;
        text-overflow: ellipsis;
        color: $gray-700;
      }

      &.active {
        .unit-icon {
          color: $primary-500;
        }

        &:after {
          content: "";
          position: absolute;
          bottom: 0px;
          left: 0px;
          top: 0px;
          right: auto;
          width: 2px;
          height: auto;
          background: $primary;
        }
      }
    }

    .dropdown-item {
      &.active {
        // This is un-doing the default react-bootstrap styling for active dropdown-item elements.
        background-color: transparent;
      }
    }
  }

  .previous-btn,
  .next-btn {
    border: 1px solid $light-400 !important;
    color: $gray-700;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    @media (max-width: -1 + map-get($grid-breakpoints, "sm")) {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    @media (min-width: map-get($grid-breakpoints, "sm")) {
      min-width: fit-content;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  .previous-btn {
    border-left-width: 0;
    margin-left: 0;

    @media (min-width: map-get($grid-breakpoints, "sm")) {
      border-left-width: 1px;
      border-top-left-radius: 4px;
    }
  }

  .next-btn {
    border-left-width: 1px;
    border-right-width: 0;

    @media (min-width: map-get($grid-breakpoints, "sm")) {
      border-top-right-radius: 4px;
      border-right-width: 1px;
    }
  }
}

.unit-container {
  padding: 0 $grid-gutter-width 2rem;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 830px) {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.unit-iframe-wrapper {
  margin: 0 -20px 2rem;

  @media (min-width: 830px) {
    margin: 0 -40px 2rem;
  }
}

#unit-iframe {
  width: 100%;
  border: none;
  display: block;
}

.unit-navigation {
  display: flex;
  justify-content: center;
  max-width: 640px;
  margin: 0 auto;

  .previous-button,
  .next-button {
    white-space: nowrap;
    border-radius: 4px;

    &:focus:before {
      border-radius: 6px;
    }
  }

  .next-button {
    flex-basis: 75%;
  }

  .previous-button {
    flex-basis: 25%;
  }
}

// This class forces any modals using 'modal-lti' as their dialogClassName to take up the whole
// window (retaining padding around the edge).  Bootstrap modals don't have a full-screen
// size like this.  Because of the hack below around react-focus-on's div, it would be better long-term to pull this into Paragon and perhaps call it "modal-full" or something like that.
.modal-lti {
  height: 100%;
  max-width: 100%;

  // I don't like this.  We need to set a height of 100% on a div created by react-focus-on, a
  // package we use in our Modal.  That div has no class name or ID, so instead we're uniquely
  // identifying it by based on a unique attribute it has which its siblings don't share.
  >div[data-focus-lock-disabled="false"] {
    height: 100%;
  }

  // Along with setting the height of modal-content's parent div from react-focus-on, we need to
  // set modal-content's height as well to get the modal to expand to full-screen height.
  .modal-content {
    height: 100%;
  }
}

.greyed-out {
  opacity: 0.3;
}

.locked-overlay {
  opacity: 0.3;
  pointer-events: none;

  &.grades {
    overflow: hidden;
    max-height: 852px;

    @media screen and (min-width: 992px) {
      max-height: 920px;
    }
  }
}

.sf-container {
  padding: 16px 60px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
  }

  .sf-recent-course-banner__title {
    color: #aafc4e;
  }
}

.sf-footer-component__credit {
  // TODO move to frontend-component-footer
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

#hde-container {
  right: 15px !important;
}

// Import component-specific sass files
@import "courseware/course/celebration/CelebrationModal.scss";
@import "courseware/course/NotificationTray.scss";
@import "courseware/course/NotificationTrigger.scss";
@import "courseware/course/NotificationIcon.scss";
@import "courseware/course/sequence/lock-paywall/LockPaywall.scss";
@import "shared/streak-celebration/StreakCelebrationModal.scss";
@import "courseware/course/content-tools/calculator/calculator.scss";
@import "courseware/course/content-tools/contentTools.scss";
@import "course-home/dates-tab/timeline/Day.scss";
@import "generic/upgrade-notification/UpgradeNotification.scss";
@import "course-home/outline-tab/widgets/ProctoringInfoPanel.scss";
@import "course-home/progress-tab/course-completion/CourseCompletion.scss";
@import "course-home/progress-tab/course-completion/CompletionDonutChart.scss";
@import "course-home/progress-tab/grades/course-grade/GradeBar.scss";
@import "courseware/course/course-exit/CourseRecommendations";

/** [MM-P2P] Experiment */
@import "experiments/mm-p2p/index.scss";

// SkillFactory
@import '@skillfactory/frontend-theme/dist/theme.min.css';
@import '@skillfactory/tora-frontend/dist/tora.min.css';
@import 'components/page-layout/page-layout';
@import 'auth/auth-page-layout/auth-page-layout';
@import 'dashboard/dashboard';
@import 'dashboard/dashboard-section/dashboard-section';
@import 'dashboard/dashboard-section/dashboard-section';
@import 'dashboard/career-center-slider/career-card/career-card';
@import 'dashboard/career-center-slider/career-card/career-card';
@import 'courseware/courseware-page/courseware';
@import 'courseware/courseware-page/courseware';
@import 'courseware/sequence-tab-view/sequence-tab-view';
@import 'courseware/sequence-tab-view/sequence-tab/sequence-tab';
@import 'course-home/outline-tab/DateSummary';
@import 'course-home/outline/outline';
@import 'course-home/outline/header/outline-header';
@import 'course-home/outline/projects-banner/projects-banner';
@import 'course-home/outline/subsection-list/subsection-list';
@import 'course-home/outline/subsection-list/subsection/subsection';
@import 'course-home/progress-tab/related-links/RelatedLinks';
@import 'forbidden/forbidden';
@import 'instructor-toolbar/InstructorToolbar';


// TODO Стилизовать прогресс и покрасить его в темную тему
.sf-progress-tab {
  background-color: var(--color-white-shared);

  &__loader-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &--bar-chart {
      height: 420px;
    }

    &--weighted-grades {
      height: 327px;
    }
  }

  &__weighted-grades-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 70px;
  }

  &__detailed-grades-container {
    display: flex;
    flex-direction: column;
  }

  &__visible-section {
    padding-bottom: 32px;

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-gray-5);
      margin-bottom: 32px;
    }
  }

  &__header-text {
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -2%;
    margin-bottom: 12px;
  }

  &__sub-text {
    margin-bottom: 24px;
  }

  &__warning {
    width: fit-content;
    margin-top: 5px;
  }

  &__grades-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  &__modal-buttons-container {
    display: flex;
    gap: 20px;
  }

  &__modal-input {
    &--invalid input {
      color: var(--color-service-red);
    }
  }
}

#foquz-poll-modal {
  z-index: 10001 !important;
}
